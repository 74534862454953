<template>
    <div>
        <div class="profile-page">
            <section class="profile-height section-profile-cover my-0"></section>
            <section :class="{'section': desktop}">
                <div class="container pt-lg-md">
                    <card shadow class="card-profile mt--300" no-body>
                        <div class="px-4">
                            <div class="row justify-content-center">
                                <div class="col-lg-3 order-lg-2">
                                    <div class="card-profile-image">
                                        <div v-if="this.$route.params.id == this.$store.state._id">
                                            <img style="z-index: 9999" v-if="model.image" id="pick-avatar" :src="model.image" @error="model.image = false" class="rounded-circle hand">
                                            <img style="z-index: 9999" v-else id="pick-avatar" :src="'../img/svg/avatar.svg'" class="rounded-circle hand" @click="$gtag.event('edit_avatar')">
                                        </div>
                                        <div v-else>
                                            <img v-if="model.image" :src="model.image" @error="model.image = false" class="rounded-circle">
                                            <img v-else :src="'../img/svg/avatar.svg'" class="rounded-circle">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 order-lg-3 text-lg-right align-self-lg-center">
                                    <div :class="{'card-profile-actions': true, 'py-5': desktop, 'py-2': !desktop}">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                </div>
                            </div>
                            <div :class="[{'py-4': !desktop}, {'py-5': desktop}, {'text-center': true}]">
                                <div>
                                    <h3 v-if="this.$route.params.id == this.$store.state._id" class="mt-2">{{model.name.full}}</h3>
                                    <h3 v-else>{{model.name.first}}</h3>
                                    <badge type="primary">{{$t(model.type)}}</badge>
                                </div>
                            </div>
                            <div v-if="!(this.$route.params.id != this.$store.state._id && model.bio == 'bio')" :class="{'py-4': !desktop, 'py-5': desktop, 'border-top': true, 'text-center': true}">
                                <div v-if="(this.$route.params.id == this.$store.state._id) || (this.$route.params.id != this.$store.state._id && model.bio != 'bio')" class="row justify-content-center">
                                    <div v-if="this.$route.params.id == this.$store.state._id && editBio" class="col-lg-9">
                                        <textarea :maxlength="bioMaxLength.toString()" class="form-control form-control-alternative" rows="3" :placeholder="model.bio == 'bio' ? $t('global.' + model.bio) : model.bio" v-on:input="model.bio = $event.target.value"></textarea>
                                        <small class="mt-2" style="float: left; color:lightgrey" v-html="leftLength(bioMaxLength, model.bio.length)"></small>
                                        <div style="float:right;">
                                            <base-button type="primary" size="sm" class="my-4" @click="editBio = false; model.bio = previousBio;">{{$t('profile.cancel')}}</base-button>
                                            <base-button type="primary" size="sm" class="my-4" @click="updateUser('bio'); editBio = false; previousBio = model.bio">{{$t('profile.save')}}</base-button>
                                        </div>
                                    </div>
                                    <div v-else @click="editBio = true; $gtag.event('edit_bio')" :class="{'col-lg-9': true, 'hand': this.$route.params.id == this.$store.state._id, 'text-justify': model.bio.length > 100}">
                                        {{model.bio == 'bio' ? $t('global.' + model.bio) : model.bio}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </card>
                </div>
            </section>
        </div>
        <avatar-cropper @uploaded="handleUploaded"
                        trigger="#pick-avatar"
                        :upload-handler="uploadImage"
                        :labels="{'submit': $t('global.submit'), 'cancel': $t('global.cancel')}"
                        :output-quality="1"/>
        <!--<modal :show.sync="modal" adaptative>
            <img class="rounded-circle" type="mini" v-lazy="model.image" />
        </modal>-->
        <!--<loading :show="isLoading" label=""></loading>-->        
    </div>
</template>
<script>
    import AvatarCropper from "vue-avatar-cropper";
    import $ from 'jquery';

    export default {
        data() {
            return {
                model: {
                    name: {
                        full: '',
                        name: '',
                        surname: '',
                    },
                    bio: '',
                },
                bioMaxLength: 1000,
                editBio: false,
                previousBio: '',
                isLoading: true,
                modal: true,
                desktop: screen.width > 570,
            }
        },

        components: {
            AvatarCropper,
        },

        watch: {
            'model.bio'() {
                if (this.model.bio.length > this.bioMaxLength) {
                    this.model.bio = this.model.bio.slice(0, 1000);
                }
            },
            deep: true,
        },

        methods: {
            leftLength(maxLength, length) {
                return (maxLength - length > 0 ? maxLength - length : '<div style="color: red">0</div>')
            },
            uploadImage(cropper) {
                this.model.image = cropper.getCroppedCanvas({width: 300, height: 300}).toDataURL(this.cropperOutputMime);
                this.updateUser('image');
            },

            handleUploaded(resp) {
                this.userAvatar = resp.relative_url;
            },

            showModal() {
                if (this.$route.params.id == this.$store.state._id) {
                    this.$modal.show('modal-1');
                } else if (this.model.image) {
                    this.$modal.show('modal-2');
                }
            },

            switchLoading() {
                this.isLoading = !this.isLoading;
            },

            loadModel(model) {
                this.model = model;
                this.model._id = this.$route.params.id;
                this.previousBio = model.bio;
            },

            updateInput(key, value) {
                this.model[key] = value;
            },

            switch(element) {
                this.$data[element] = !this.$data[element];
            },

            updateUser(attribute) {
                var model = { _id: this.$store.state._id, type: this.model.type }
                model[attribute] = this.model[attribute]
                var switchLoading = this.switchLoading;
                switchLoading();
                $.ajax({
                    url: 'https://api.immobl.com/update',
                    contentType: 'application/json;charset=UTF-8',
                    xhrFields: {
                        withCredentials: true
                    },
                    crossDomain: true,
                    type: 'POST',
                    data: JSON.stringify({ data: model }),
                    success: function (response) {
                        if (response.success) {
                            switchLoading();
                        }
                    },
                    //error: function (err) {
                    //    //console.log(err);
                    //    switchLoading();
                    //}
                });
            },            
        },

        mounted() {
            this.$gtag.set({ 'user_id': this.$store.state._id });
            var loadModel = this.loadModel;
            const router = this.$router

            $.ajax({
                url: 'https://api.immobl.com' + this.$route.path,
                type: 'GET',
                xhrFields: {
                    withCredentials: true
                },
                crossDomain: true,
                success: function (response) {
                    if (response.success) {
                        loadModel(response.user);
                    } else {
                        router.push('/dashboard')
                    }
                },
                //error: function (err) {
                //    //console.log(err);
                //}
            });
        }
    };
</script>
<style>
    .hand {
        cursor: pointer;
    }

    .profile-height {
        height: 325px !important;
    }

    @media screen and (max-width: 570px) {
        .profile-height {
            height: 250px !important;
        }
    }

</style>
